export default {
    async getBestProxy(apiUrls, url, apiKey) {
        let promises = apiUrls.map(async apiUrl => {
            let res = await fetch(apiUrl + '/api/ping?url=' + encodeURIComponent(url) + '&apiKey=' + apiKey);

            if (![200, 400, 401, 408, 413].includes(res.status)) {
                throw new Error('An error occurred.')
            }

            return {
                apiUrl: apiUrl,
                data: await res.json()
            };
        });

        let response = await Promise.race(promises);

        if (response.data.message) {
            return {
                success: false,
                message: response.data.message
            };
        }

        return {
            success: true,
            data: `${response.apiUrl}/api/proxy?url=${encodeURIComponent(url)}&apiKey=${apiKey}`
        };
    }
}