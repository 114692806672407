<script>
    import Down4meProxy from '../libs/proxy';
    let apiUrls = process.env.API_BASE_URL.split(',');
    let ANONYMOUS_SIZE = process.env.ANONYMOUS_SIZE || '100MB';
    let FREE_USER_SIZE = process.env.FREE_USER_SIZE || '200MB';
    let PAID_USER_SIZE = process.env.PAID_USER_SIZE || '2GB';

    let apiKey = localStorage.getItem('apiKey') || ''
    let proxyUrl = localStorage.getItem('proxyUrl') || '';
    let errorMessage = '';
    let outputUrl = '';
    let loading = false;

    async function submit() {
        try {
            new URL(proxyUrl)
        } catch (e) {
            errorMessage = 'Invalid URL.';
            return false;
        }

        errorMessage = '';
        outputUrl = '';
        loading = true;

        try {
            let result = await Down4meProxy.getBestProxy(apiUrls, proxyUrl, apiKey);
            loading = false;
            if (!result.success) {
                errorMessage = result.message;
                return false;
            }
            outputUrl = result.data;
        } catch (e) {
            console.log(e);
            errorMessage = e.message || 'An error occurred.';
            loading = false;
        }
        return false;
    }

    function onApiKeyChange() {
        localStorage.setItem('apiKey', apiKey)
    }

    function onURLChange() {
        localStorage.setItem('proxyUrl', proxyUrl)
    }
</script>
<main>
    <h1>Down4me</h1>
    <p>Proxy your download</p>
    <div class="form">
        <div>
            <label for="url"><strong>Enter your URL</strong></label>
            <br>
            <input id="url" type="text" on:change={onURLChange} bind:value={proxyUrl} name="url">
        </div>
        <div style="margin-top: 10px">
            <label for="api-key"><strong>License (optional)</strong></label>
            <br>
            <input bind:value={apiKey} on:change={onApiKeyChange} id="api-key" type="text" name="apiKey">
        </div>
        <div style="margin-top: 10px">
            <button disabled={loading} type="button" on:click={submit}>Submit</button>
        </div>
        {#if loading}
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        {/if}
        {#if errorMessage}
            <p style="color: red">{errorMessage}</p>
        {/if}
        {#if outputUrl}
            <div style="margin-top: 10px">
                <label for="output" style="color: green"><strong>Your download link</strong></label>
                <br>
                <input bind:value={outputUrl} id="output" type="text">
                <a href={outputUrl} target="_blank">Download</a>
            </div>
        {/if}
        <br>
        <div style="overflow-y: auto; margin-top: 10px">
            <table>
                <thead>
                <tr>
                    <th>License Limit</th>
                    <th>Timeout</th>
                    <th>Max Proxy Size</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Anonymous</td>
                    <td>15s</td>
                    <td>{ANONYMOUS_SIZE}</td>
                </tr>
                <tr>
                    <td>Free User</td>
                    <td>30s</td>
                    <td>{FREE_USER_SIZE}</td>
                </tr>
                <tr>
                    <td>Paid User</td>
                    <td>120s</td>
                    <td>{PAID_USER_SIZE}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <br>
        <div>Contact: <a href="mailto:dangdungcntt@gmail.com">dangdungcntt@gmail.com</a></div>
    </div>
</main>

<style>
    main {
        text-align: center;
        padding: 1em;
        max-width: 300px;
        margin: 0 auto;
    }

    h1 {
        color: #ff3e00;
        text-transform: uppercase;
        font-size: 4em;
        font-weight: 100;
    }

    .form {
        max-width: 400px;
        margin: 0 auto;
    }

    input {
        width: 100%;
    }

    table {
        width: 100%;
        border-spacing: 0;
        border-collapse: separate;
    }

    td, th {
        padding: 8px 12px;
    }

    table, td, th {
        border: 1px solid #eee
    }

    @media (min-width: 640px) {
        main {
            max-width: none;
        }
    }

    @media (max-width: 640px) {
        h1 {
            font-size: 3em;
        }
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 40px;
    }

    .lds-ellipsis div {
        position: absolute;
        top: 20px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #686868;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }

</style>