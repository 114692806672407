<script>
    import Down4meProxy from "../libs/proxy";

    import { onMount } from "svelte";

    onMount(async () => {
        try {
            let proxyHosts = process.env.API_BASE_URL.split(",");
            const { searchParams } = new URL(location.href);
            let url = searchParams.get("url");
            let apiKey = searchParams.get("apiKey") || "";

            let result = await Down4meProxy.getBestProxy(
                proxyHosts,
                url,
                apiKey
            );

            if (result.success) {
                location.href = result.data;
                return;
            }

            document.write('Error');
        } catch (e) {
            console.log(e);
            document.write(e.message);
        }
    });
</script>
