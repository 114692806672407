<script>
    import { Router, Route } from "svelte-navigator"
    import Home from "./pages/Home.svelte";
    import Proxy from "./pages/Proxy.svelte";
</script>

<Router primary={false}>
    <Route path="/proxy">
        <Proxy />
    </Route>

    <Route path="/">
        <Home />
    </Route>
</Router>